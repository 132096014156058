<template>
	<div class="wrapper">
		<div class="header">
			<img v-if="titleImage" class="header-image" :src="titleImage">
			<p class="header-title">{{ title }}</p>
		</div>

		<card-component class="has-table" :hasButtonSlot="true" title="" icon="">
			<b-table
				:loading="isLoading"
				:paginated="false"
				:per-page="$consts.TABLE_PER_PAGE"
				:hoverable="true"
				default-sort="name"
				:data="menus"
				@page-change="onPageChange"
				@click="onMenuClick"
				>

				<b-table-column field="image" v-slot="props">
					<div class="menu-image"><img :src="props.row.image"></div>
				</b-table-column>
				<b-table-column label="名前" field="name" v-slot="props">
					{{ props.row.name }}
				</b-table-column>
				<b-table-column label="入数" field="quantity" v-slot="props">
					{{ props.row.quantity_price }}
				</b-table-column>
				<b-table-column label="価格(税抜)" field="prie" v-slot="props">
					{{ props.row.price.toLocaleString() }}円
				</b-table-column>

				<section class="section" slot="empty">
					<table-empty :loading="isLoading"></table-empty>
				</section>

				<section class="page-info" slot="bottom-left">
					<page-info :current="currentPage" :data="menus"></page-info>
				</section>
			</b-table>
		</card-component>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_order.scss';
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import MySectionComponent from '@/components/MySectionComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import { mapState } from 'vuex'

export default {
	name: 'Order',
	components: {
		//MySectionComponent
		CardComponent,
		TableEmpty,
		PageInfo,
	},
	data () {
		return {
			isLoading: false,
			id: '',
			menus: [],
			currentPage: 1,
			titleImage: '',
			title: '',
		}
	},
	computed: {
		...mapState([
			'brands',
			'categories',
		])
	},
	created() {
		this.id = this.$route.params.id;
	},
	mounted () {
		this.init();
	},
	methods: {
		onMenuClick: function(obj) {
			this.$router.push({ name: 'menu', params: { menuId: obj.id }});
		},
		init: function() {
			// タイトル設定
			if (this.$route.params.type === this.$consts.ORDER_TYPE_BRAND) {
				this.title = this.brands.filter(param => { return param.id === this.id }, this)[0].name;
				this.titleImage = this.brands.filter(param => { return param.id === this.id }, this)[0].image;
			} else {
				this.title = this.categories.filter(param => { return param.id === parseInt(this.id) }, this)[0].name;
			}

			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let ep = `menus/type/${this.$route.params.type}/${this.$route.params.id}`;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.menus = res.data.list;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onPageChange: function(val) {
			this.currentPage = val;
		},
	},
}
</script>

