<template>
	<div class="wrapper">
		<my-section-component class="menu-wrapper" title="" icon="">
			<div v-if="!isNodata">
				<component :is="menuDetailComponent" :menu="menu" @change="onOrderQuantityChange"></component>

				<div class="mt-40 mb-10" style="font-weight:bold; font-size: 1.1rem;">【関連商品】</div>

				<ul v-if="recommends.length > 0" class="recommend">
					<li v-for="row in recommends" :key="row.id">
						<menu-detail :menu="row" :isContentTitleShow="false" @change="onOrderQuantityChange"></menu-detail>
					</li>
				</ul>
				<p v-else>関連商品はありません</p>
			</div>

			<div v-else>
				商品はありません。
			</div>

			<div class="mt-30">
				<b-button @click="$router.go(-1)">戻る</b-button>
				<b-button v-if="!isNodata" class="is-primary ml-30" :disabled="isOrderDisabled" @click="onOrderClick">カートに入れる</b-button>
			</div>
		</my-section-component>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_menu.scss';
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import MySectionComponent from '@/components/MySectionComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import MenuDetail from '@/components/MenuDetail'
import { mapState } from 'vuex'

export default {
	name: 'Menu',
	components: {
		MySectionComponent,
		MenuDetail,
	},
	data () {
		return {
			menu: null,
			recommends: [],
			currentOrders: {},
			isLoading: false,
			isOrderDisabled: true,
			menuDetailComponent: null,
			isNodata: false,
		}
	},
	computed: {
	},
	created() {
	},
	mounted () {
		this.init();
	},
	methods: {
		onOrderClick: async function() {
			let isAlert = false;
			for (let key in this.currentOrders) {
				if (this.currentOrders[key] >= 15) {
					isAlert = true;
					break;
				}
			}

			if (isAlert && !window.confirm('発注数が15以上になっています。カートに入れますか？')) return false;

			let loader = this.$buefy.loading.open();
			let ep = 'orders'
			this.$utils.log(ep);

			try {
				let res = await this.$api.post(ep, this.currentOrders, true);
				this.$utils.log(res);
				this.isMenuModalActive = false;
				this.$utils.toastSuccess('カートに入りました');
				this.$router.go(-1);
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onOrderQuantityChange: function(qty, menuId) {
			this.currentOrders[menuId] = qty;
			if (qty === 0) delete this.currentOrders[menuId];

			//this.$utils.log(this.currentOrders);
			//this.$utils.log(Object.keys(this.currentOrders).length);
			this.isOrderDisabled = Object.keys(this.currentOrders).length === 0;
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let ep = `menus/${this.$route.params.menuId}`;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.menu = res.data;
				this.recommends = res.data.recommends;
				this.menuDetailComponent = 'menu-detail';
			} catch (error) {
				if (error.response.status === 400) {
					this.isNodata = true;
				} else {
					this.$utils.log(error.response);
					this.$utils.toastError(error.response.data.message);
				}
			} finally {
				loader.close();
			}
		},
	},
}
</script>

