<template>
	<div class="menu">
		<div class="menu_header">
			<p class="menu_header-code">商品番号：{{ menu.code }}</p>
			<p class="menu_header-title">{{ menu.name }}</p>
		</div>

		<div class="menu-contents">
			<img class="menu-image" :src="menu.image">
			<p class="menu-quantity"><span v-if="isContentTitleShow">入数：</span>{{ menu.quantity_price }}</p>
			<p class="menu-lead_time"><span v-if="isContentTitleShow">納期：</span>{{ menu.lead_time }}</p>
			<p class="menu-pricewrapper">
				<span v-if="isContentTitleShow">価格：</span>
				<span v-if="menu.unit" class="menu-unit">1{{ menu.unit }}</span>
				<span class="menu-price">{{ menu.price.toLocaleString() }}円(税抜)</span>
			</p>

			<div class="menu-inputwrapper">
				<b-numberinput type="is-success" min="0" v-model="orderQuantity" @input="onQuantityChange"></b-numberinput>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.menu_header {
	&-title {
		font-weight: bold;
		font-size: 1.2rem;
		margin: 5px 0;
	}
}

.menu {
	width: 100%;
	&-quantity {
		margin-bottom: 5px;
	}

	&-unit {
		margin-right: 10px;
	}

	&-price {
		font-weight: bold;
		font-size: 1.1rem;
	}

	&-inputwrapper {
		margin-top: 30px;
	}
}

::v-deep .button {
	width: initial;
}
</style>

<script>

export default {
	name: 'MenuDetail',
	props: {
		menu: {
			type: Object,
			default: null,
		},
		isContentTitleShow: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			orderQuantity: 0,
		}
	},
	computed: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		onQuantityChange: function() {
			//this.$utils.log('onQuantityChange');
			//this.$utils.log(this.orderQuantity);
			this.$emit('change', this.orderQuantity, this.menu.id);
		},
	},
}
</script>
