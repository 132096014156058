<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧" icon="format-list-bulleted">

				<b-table
					ref="table"
					:loading="isLoading"
					:paginated="true"
					:per-page="$consts.TABLE_PER_PAGE"
					:striped="true"
					:hoverable="true"
					:data="list"
					:opened-detailed="openDetails"
					detailed
					detail-key="id"
					:show-detail-icon="false"
					@page-change="onPageChange"
					>

					<b-table-column label="日付" field="date" sortable v-slot="props">
						{{ props.row.date }}
					</b-table-column>
					<b-table-column label="注文番号" field="number" sortable v-slot="props">
						{{ props.row.number }}
					</b-table-column>
					<b-table-column label="※送料" field="shipping_fee" v-slot="props">
						{{ props.row.shipping_fee.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="小計" field="sub_total" v-slot="props">
						{{ props.row.sub_total.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="消費税(8%)" field="tax_total" v-slot="props">
						{{ props.row.tax_class2.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="※消費税(10%)" field="tax_total" v-slot="props">
						{{ props.row.tax_class1.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="合計" field="grand_total" v-slot="props">
						{{ props.row.grand_total.toLocaleString() }}円
					</b-table-column>

					<template #detail="props">
						<ul class="order_detail">
							<li v-for="row in props.row.details" :key="row.id">
								<span class="order_detail-lead_time">納期：{{ row.menu_lead_time }}</span>
								<div class="order_detail-contents_wrapper">
									<img class="order_detail-image" :src="row.menu_image">
									<div class="order_detail-info">
										<span class="order_detail-code">{{ row.menu_code }}</span>
										<a href="" @click.prevent="$router.push({ name: 'menu', params: { menuId: row.menu_id }})">
											<span v-if="row.menu_tax_class_id === 1" class="order_detail-name">※{{ row.menu_name }}</span>
											<span v-else class="order_detail-name">{{ row.menu_name }}</span>
										</a>
										<span class="order_detail-price">{{ row.menu_price.toLocaleString()}}円(税抜)</span>
										<span class="order_detail-quantity">{{ row.quantity }} {{ row.menu_unit }}</span>
									</div>
								</div>
							</li>
						</ul>
					</template>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>

					<section class="page-info" slot="bottom-left">
						<page-info :current="currentPage" :data="list"></page-info>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<style lang="scss" scoped>
@import '../scss/_history.scss';
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'

export default {
	name: 'History',
	components: {
		TitleBar,
		CardComponent,
		TableEmpty,
		PageInfo,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			currentPage: 1,
			openDetails: [],
			unsubscribe: null,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
	},
	befereDestroy() {
		this.unsubscribe();
	},
	beforeRouteLeave (to, from, next) {
		this.unsubscribe();
		next();
	},
	mounted () {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'changeStore') this.init();
		});
		this.init();
	},
	methods: {
		init: function() {
			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'orders/history';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
				this.openDetails = res.data.ids;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onPageChange: function(val) {
			this.currentPage = val;
		},
	},
}
</script>
