<template>
	<div class="wrapper">
		<div class="header">
			<p class="header-title">カート</p>
		</div>

		<card-component class="has-table" :hasButtonSlot="true" :title="date + ' ご注文分'" icon="">
			<b-table
				:loading="isLoading"
				:paginated="false"
				:per-page="$consts.TABLE_PER_PAGE"
				:hoverable="true"
				:data="list"
				>

				<b-table-column label="ブランド" field="menu_brand_name" v-slot="props">
					{{ props.row.menu_brand_name }}
				</b-table-column>
				<b-table-column label="商品" field="menu_name" v-slot="props">
					<span v-if="props.row.menu_tax_class_id === 1">※{{ props.row.menu_name }}</span>
					<span v-else>{{ props.row.menu_name }}</span>
				</b-table-column>
				<b-table-column label="単価" field="menu_prie" numeric v-slot="props">
					{{ props.row.menu_price.toLocaleString() }}円
				</b-table-column>
				<b-table-column label="数量" field="quantity" numeric v-slot="props">
					<span v-show="!isQtyEdit(props.row.id)">{{ props.row.quantity }}</span>
					<div v-show="isQtyEdit(props.row.id)" class="qty_edit">
						<b-numberinput type="is-success" min="1" v-model="orderQuantity"></b-numberinput>
						<div class="qty_edit-buttons">
							<button class="button is-small" type="button" @click="qtyEditClose">キャンセル</button>
							<button class="button is-small is-info" type="button" @click="onQtyUpdateClick(props.row.id)">更新</button>
						</div>
					</div>
				</b-table-column>
				<b-table-column label="価格" field="prie" numeric v-slot="props">
					{{ props.row.menu_total_price.toLocaleString() }}円
				</b-table-column>

				<b-table-column label=" " v-slot="props">
					<div v-show="!isQtyEdit(props.row.id)" class="buttons is-right">
						<button class="button is-small is-warning" type="button" @click="onQtyEditClick(props.row)">数量変更</button>
						<button class="button is-small is-danger" type="button" @click="onDeleteClick(props.row.id)">削除</button>
					</div>
				</b-table-column>

				<section class="section" slot="empty">
					<table-empty :loading="isLoading"></table-empty>
				</section>

				<template #footer>
					<div v-show="list.length > 0" class="has-text-right">
						<ul class="tf">
							<li>
								<p class="tf-title">※送料</p>
								<p class="tf-price">{{ shippingFee.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">小計</p>
								<p class="tf-price">{{ subTotal.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">消費税(8%)</p>
								<p class="tf-price">{{ taxClass2.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">※消費税(10%)</p>
								<p class="tf-price">{{ taxClass1.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">合計</p>
								<p class="tf-price">{{ grandTotal.toLocaleString() }}円</p>
							</li>
						</ul>

						<p class="notice">ご注文は翌午前0時に確定します</p>
					</div>
				</template>
			</b-table>
		</card-component>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_cart.scss';
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import MySectionComponent from '@/components/MySectionComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import MenuDetail from '@/components/MenuDetail'
import { mapState } from 'vuex'

export default {
	name: 'Cart',
	components: {
		CardComponent,
		TableEmpty,
	},
	data () {
		return {
			isLoading: false,
			date: '',
			list: [],
			subTotal: 0,
			taxClass1: 0,
			taxClass2: 0,
			grandTotal: 0,
			shippingFee: 0,
			editRowId: null,
			orderQuantity: null,
			unsubscribe: null,
		}
	},
	computed: {
	},
	created() {
	},
	befereDestroy() {
		this.unsubscribe();
	},
	beforeRouteLeave (to, from, next) {
		this.unsubscribe();
		next();
	},
	mounted () {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'changeStore') this.init();
		});
		this.init();
	},
	methods: {
		qtyEditClose: function() {
			this.orderQuantity = null;
			this.editRowId = null;
		},
		isQtyEdit: function(id) {
			return id === this.editRowId;
		},
		onQtyUpdateClick: async function(id) {
			let loader = this.$buefy.loading.open();
			let ep = 'order-details/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.put(ep, { "quantity": this.orderQuantity }, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('数量を変更しました');
				this.qtyEditClose();
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onQtyEditClick: function(obj) {
			this.orderQuantity = obj.quantity;
			this.editRowId = obj.id;
		},
		onDeleteClick: async function(id) {
			if (!window.confirm('選択した商品を削除します。よろしいですか？')) return false;

			let loader = this.$buefy.loading.open();
			let ep = 'order-details/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				
				this.list.some(function(value, index) {
					if (value.id === id) this.list.splice(index, 1);
				}, this);

				this.$utils.toastSuccess('商品を削除しました');
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let ep = 'orders';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.date = res.data.date_jp;
				this.list = res.data.details;
				if (this.list.length > 0) {
					this.subTotal = res.data.sub_total;
					this.taxClass1= res.data.tax_class1;
					this.taxClass2= res.data.tax_class2;
					this.grandTotal = res.data.grand_total;
					this.shippingFee = res.data.shipping_fee;
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
	},
}
</script>

